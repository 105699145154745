import React, { useState, useEffect, useContext } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { Form, Button, message, Input } from "antd";
import PrediosSelect from "./components/prediosCarrossel";
import api from "../../api";
import { LoadingOutlined } from "@ant-design/icons";
import { navigate } from "gatsby";
import { AuthContext } from "../../context/GlobalContextProvider";

export default function BivverDream() {
  const [page, setPage] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const context = useContext(AuthContext);
  useEffect(() => {
    getPage();
  }, []);

  const getPage = async () => {
    setLoading(true);
    const { data } = await api.get("/bivver-dream");
    setPage(data);
    setLoading(false);
  };
  const handleSubmit = async values => {
    setLoadingSave(true);
    var data = new FormData();
    data.append(
      "data",
      JSON.stringify({
        predios: values.carousel,
        conteudo: values.conteudo
      })
    );

    await api({
      method: "PUT",
      url: "/bivver-dream",
      headers: {
        Authorization: `Bearer ${context?.token}`
      },
      data
    }).then(value => {
      if (value) {
        message.success("Página salva com sucesso");
        navigate(`/nossasLinhas/bivverDream`);
      }
    });
    setLoadingSave(false);
  };

  return (
    <Layout loading={loading}>
      <SEO title="Bivver Dream" />
      <Form onFinish={handleSubmit}>
        <PrediosSelect
          classe="BivverADream"
          carrossel
          initialValue={page?.predios}
          {...{ Form, name: "carousel" }}
        />
        <center>
          <h2>BIVVER DREAM</h2>
        </center>
        <Form.Item initialValue={page?.conteudo} name="conteudo">
          <Input.TextArea autoSize placeholder="Conteúdo" />
        </Form.Item>
        <Form.Item>
          <Button
            className="mt-10"
            style={{ backgroundColor: "orange", color: "white" }}
            htmlType="submit"
          >
            {loadingSave && <LoadingOutlined />} Salvar
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
}
